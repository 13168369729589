import React, { useState } from 'react';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';
import { injectIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import {
  Button,
  FieldCheckbox,
  FieldDateInput,
  FieldDateRangeController,
  FieldDateRangeInput,
  FieldLocationAutocompleteInput,
  FieldRadioButton,
  FieldTextInput,
  Form,
  OutsideClickHandler,
} from '../../../../components';
import { listingFields, priceCatergory } from '../../../../config/configListing';
import moment from 'moment';

import css from './SectionContainer.module.css';
import { FieldCheckboxGroup } from '../../../../components';
import { useConfiguration } from '../../../../context/configurationContext';

const SearchFieldsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize={true}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        form,
        formId,
        values,
        autoFocus,
        invalid,
      } = fieldRenderProps;
      const buyout = priceCatergory[0].enumOptions;
      const [showContent, setShowContent] = useState(false);
      const handleButtonClick = () => {
        setShowContent(!showContent); // Toggle the state
      };

      const [showdate, setShowDates] = useState(false);
      const handledateClick = () => {
        setShowDates(!showdate); // Toggle the state
      };

      const [showkeyword, setShowKeyword] = useState(false);
      const handlekeywordClick = () => {
        setShowKeyword(!showkeyword); // Toggle the state
      };
      const config = useConfiguration();
      const { dateRangeMode } = config;
      const isNightlyMode = dateRangeMode === 'night';

      // const formatSelectedDateRange = (startDate, endDate) => {
      //     const formattedStartDate = new Date(startDate).toDateString();
      //     const formattedEndDate = new Date(endDate).toDateString();
      //     return `${formattedStartDate} - ${formattedEndDate}`;
      // };

      const formatSelectedDateRange = date => {
        const start = moment(date).format('ddd, DD MMM YYYY');

        return start;
      };

      return (
        <Form onSubmit={handleSubmit} className={css.heroFilterWrapper}>
          <div className={css.formBorder}>
            <div className={css.dateWrapper}>
              <div className={css.taskSelect} onClick={handledateClick}>
                {values.date && values.date.date
                  ? formatSelectedDateRange(values.date.date)
                  : 'All dates'}
              </div>
              {showdate && (
                <OutsideClickHandler onOutsideClick={handledateClick} className={css.dateDropdown}>
                  <div>
                    <FieldDateRangeController
                      name="date"
                      id="date"
                      minimumNights={isNightlyMode ? 1 : 0}
                      //   controllerRef={node => {
                      //     this.plainControllerRef = node;
                      //   }}
                    />
                  </div>
                </OutsideClickHandler>
              )}
            </div>
            <div className={css.taskCapacity}>
              {/* <div className={css.label} onClick={handlekeywordClick}>
                                Any Capacity
                            </div> */}
              {/* {showkeyword && ( */}

              <FieldTextInput
                className={css.taskInput}
                id={'keyword'}
                name={'keyword'}
                label={''}
                placeholder="Any Capacity"
              />

              {/* )} */}
            </div>

            <div className={css.taskWrapper}>
              <div className={css.taskSelect} onClick={handleButtonClick}>
                {values.buyout
                  ? values.buyout
                      .map(b => {
                        switch (b) {
                          case 'one_doller':
                            return '$';

                          case 'two_doller':
                            return '$$';

                          case 'three_doller':
                            return '$$$';

                          case 'four_doller':
                            return '$$$$';

                          default:
                            return '';
                        }
                      })
                      .join(', ')
                  : 'Any space'}
              </div>
              {showContent && (
                <OutsideClickHandler onOutsideClick={handleButtonClick} className={css.spaceValue}>
                  <div>
                    {buyout
                      .filter(e => e.option) // Filter out elements with empty 'option' property
                      .map(e => (
                        <FieldRadioButton
                          key={e.label}
                          className={css.taskCheckbox}
                          id={`buyout_${e.label}`}
                          name="buyout"
                          label={e.label}
                          options={e.option}
                          value={e.option}
                        />
                      ))}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <button className={css.searchDesktopButton} onClick={() => handleSubmit} button="submit">
            DISCOVER
          </button>
        </Form>
      );
    }}
  />
);

const SearchComponent = compose(injectIntl)(SearchFieldsFormComponent);
SearchComponent.displayName = 'Search Component';
export default SearchComponent;
