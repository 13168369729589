import React from 'react';
import { arrayOf, bool, func, node, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// Section components
import SectionArticle from './SectionArticle';
import SectionCarousel from './SectionCarousel';
import SectionColumns from './SectionColumns';
import SectionFeatures from './SectionFeatures';
import SectionHero from './SectionHero';

import Slider from 'react-slick';

// Styles
// Note: these contain
// - shared classes that are passed as defaultClasses
// - dark theme overrides
// TODO: alternatively, we could consider more in-place way of theming components
import css from './SectionBuilder.module.css';
import SectionFooter from './SectionFooter';
import { LandingCardComponent } from '../../../components/LandingListingCard/LandingListingCard';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg width="20px" height="20px" viewBox="0 0 0.6 0.6" version="1.1">
        <title>right_line</title>
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Arrow" transform="translate(-384)">
            <g id="right_line" transform="translate(384)">
              <path
                d="M0.6 0v0.6H0V0zM0.315 0.581l0 0 -0.002 0.001 -0.001 0 0 0 -0.002 -0.001q0 0 -0.001 0l0 0 0 0.011 0 0.001 0 0 0.003 0.002 0 0 0 0 0.003 -0.002 0 0 0 0 0 -0.011q0 0 0 0m0.007 -0.003 0 0 -0.005 0.002 0 0 0 0 0 0.011 0 0 0 0 0.005 0.002q0 0 0.001 0l0 0 -0.001 -0.015q0 0 -0.001 -0.001m-0.018 0a0.001 0.001 0 0 0 -0.001 0l0 0 -0.001 0.015q0 0 0 0.001l0 0 0.005 -0.002 0 0 0 0 0 -0.011 0 0 0 0z"
                id="MingCute"
                fillRule="nonzero"
              />
              <path
                d="M0.393 0.282a0.025 0.025 0 0 1 0 0.035l-0.141 0.141a0.025 0.025 0 1 1 -0.035 -0.035l0.124 -0.124 -0.124 -0.124a0.025 0.025 0 0 1 0.035 -0.035z"
                id="路径"
                fill="#09244B"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <svg width="20px" height="20px" viewBox="0 0 0.6 0.6" version="1.1">
        <title>left_line</title>
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Arrow" transform="translate(-336)">
            <g id="left_line" transform="translate(336)">
              <path
                d="M0.6 0v0.6H0V0zM0.315 0.581l0 0 -0.002 0.001 -0.001 0 0 0 -0.002 -0.001q0 0 -0.001 0l0 0 0 0.011 0 0.001 0 0 0.003 0.002 0 0 0 0 0.003 -0.002 0 0 0 0 0 -0.011q0 0 0 0m0.007 -0.003 0 0 -0.005 0.002 0 0 0 0 0 0.011 0 0 0 0 0.005 0.002q0 0 0.001 0l0 0 -0.001 -0.015q0 0 -0.001 -0.001m-0.018 0a0.001 0.001 0 0 0 -0.001 0l0 0 -0.001 0.015q0 0 0 0.001l0 0 0.005 -0.002 0 0 0 0 0 -0.011 0 0 0 0z"
                id="MingCute"
                fillRule="nonzero"
              />
              <path
                d="M0.207 0.318a0.025 0.025 0 0 1 0 -0.035l0.141 -0.141a0.025 0.025 0 1 1 0.035 0.035L0.26 0.3l0.124 0.124a0.025 0.025 0 0 1 -0.035 0.035z"
                id="路径"
                fill="#09244B"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

// These are shared classes.
// Use these to have consistent styles between different section components
// E.g. share the same title styles
const DEFAULT_CLASSES = {
  sectionDetails: css.sectionDetails,
  title: css.title,
  description: css.description,
  ctaButton: css.ctaButton,
  blockContainer: css.blockContainer,
};

/////////////////////////////////////////////
// Mapping of section types and components //
/////////////////////////////////////////////

const defaultSectionComponents = {
  article: { component: SectionArticle },
  carousel: { component: SectionCarousel },
  columns: { component: SectionColumns },
  features: { component: SectionFeatures },
  footer: { component: SectionFooter },
  hero: { component: SectionHero },
};

//////////////////////
// Section builder //
//////////////////////

const SectionBuilder = props => {
  const { sections, options } = props;
  const { sectionComponents = {}, isInsideContainer, listings, ...otherOption } = options || {};
  // If there's no sections, we can't render the correct section component
  if (!sections || sections.length === 0) {
    return null;
  }

  // Selection of Section components
  const components = { listings: listings, ...defaultSectionComponents, ...sectionComponents };
  const getComponent = sectionType => {
    const config = components[sectionType];
    return config?.component;
  };

  // Generate unique ids for sections if operator has managed to create duplicates
  // E.g. "foobar", "foobar1", and "foobar2"
  const sectionIds = [];
  const getUniqueSectionId = (sectionId, index) => {
    const candidate = sectionId || `section-${index + 1}`;
    if (sectionIds.includes(candidate)) {
      let sequentialCandidate = `${candidate}1`;
      for (let i = 2; sectionIds.includes(sequentialCandidate); i++) {
        sequentialCandidate = `${candidate}${i}`;
      }
      return getUniqueSectionId(sequentialCandidate, index);
    } else {
      sectionIds.push(candidate);
      return candidate;
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.22,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      {sections.map((section, index) => {
        const Section = getComponent(section.sectionType);
        // If the default "dark" theme should be applied (when text color is white).
        // By default, this information is stored to customAppearance field
        const isDarkTheme =
          section?.appearance?.fieldType === 'customAppearance' &&
          section?.appearance?.textColor === 'white';
        const classes = classNames({ [css.darkTheme]: isDarkTheme });
        const sectionId = getUniqueSectionId(section.sectionId, index);

        if (Section) {
          return (
            <React.Fragment key={'sectionBuilder-' + index}>
              <Section
                key={`${sectionId}_i${index}`}
                className={classes}
                defaultClasses={DEFAULT_CLASSES}
                isInsideContainer={isInsideContainer}
                options={otherOption}
                listings={listings}
                {...section}
                sectionId={sectionId}
              />
              {sectionId == 'live-availability' && listings && listings?.length ? (
                <div className={css.cardSliderWrapper}>
                  <div className={css.listingCardSlider}>
                    <h4
                      className={classNames(
                        sectionId == 'live-availability' && css.titleWithMargin,
                        css.sliderTitle
                      )}
                    >
                      {sectionId == 'live-availability' ? 'Explore the Menus' : null}
                    </h4>
                    <div className={css.lol}>
                      <Slider {...settings}>
                        {listings.map(l => (
                          <LandingCardComponent
                            className={css.listingCard}
                            listing={l}
                            showAuthorInfo={false}
                            key={l.id.uuid}
                          />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              ) : null}
              {sectionId == 'live-availability' && listings && listings?.length ? (
                <div className={css.cardSliderWrapper}>
                  <div className={css.listingCardSlider}>
                    <h4 className={classNames(css.sliderTitle)}>
                      {sectionId == 'live-availability'
                        ? 'Ambience Suited for Networking Receptions'
                        : null}
                    </h4>
                    <div className={css.llll}>
                      <Slider {...settings}>
                        {listings.map(l => (
                          <LandingCardComponent
                            className={css.listingCard}
                            listing={l}
                            showAuthorInfo={false}
                          />
                        ))}
                      </Slider>
                    </div>
                    <div className={css.seeMoreButton}>
                      <button>SEE MORE AVAILABILITY</button>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          );
        } else {
          // If the section type is unknown, the app can't know what to render
          console.warn(
            `Unknown section type (${section.sectionType}) detected using sectionName (${section.sectionName}).`
          );
          return null;
        }
      })}
    </>
  );
};

const propTypeSection = shape({
  sectionId: string,
  sectionName: string,
  sectionType: oneOf(['article', 'carousel', 'columns', 'features', 'hero']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }),
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const defaultSections = shape({
  sections: arrayOf(propTypeSection),
  options: propTypeOption,
});

const customSection = shape({
  sectionId: string.isRequired,
  sectionType: string.isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});
const propTypeOptionForCustomSections = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }).isRequired,
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const customSections = shape({
  sections: arrayOf(customSection),
  options: propTypeOptionForCustomSections.isRequired,
});

SectionBuilder.defaultProps = {
  sections: [],
  options: null,
};

SectionBuilder.propTypes = oneOf([defaultSections, customSections]).isRequired;

export default SectionBuilder;
