import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field from '../../Field';
import css from './SectionContainer.module.css';
import SearchComponent from './LandingFilterForm';
import moment from 'moment';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';

const SectionContainer = props => {
  const routeConfiguration = useRouteConfiguration();

  const {
    className,
    rootClassName,
    id,
    as,
    children,
    appearance,
    options,
    history,
    listings,
    test,
    staticContext,
    ...otherProps
  } = props;

  const Tag = as || 'section';
  const classes = classNames(rootClassName || css.root, className);
  const handleSubmit = values => {
    const { buyout, date, keyword } = values;
    const pub_price_Trier = `${buyout}`;
    const end = date && moment(date?.endDate).format('YYYY-MM-DD');
    const start = date && moment(date?.startDate).format('YYYY-MM-DD');

    const SearchParams = {
      dates: start ? `${start},${end}` : null,
      pub_total_capacity: keyword ? `${keyword}` : null,
      pub_price_Trier: buyout?.length > 0 ? pub_price_Trier : null,
    };

    history?.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, SearchParams));
  };
  return (
    <Tag className={classes} id={id} {...otherProps}>
      {appearance?.fieldType === 'customAppearance' ? (
        <Field
          data={{ alt: `Background image for ${id}`, ...appearance }}
          className={id == 'hero' ? css.heroBackground : className}
          options={options}
        />
      ) : null}

      <div
        className={
          id === 'hero'
            ? css.heroContent
            : id === 'used-by'
            ? css.usedByContent
            : id === 'full-buyouts'
            ? css.fullBuyouts
            : id === 'live-availability'
            ? css.liveAvailability
            : id === 'hosted-boston-box'
            ? css.hostedBostonBox
            : id === 'company-boston-offsite'
            ? css.companyBostonOffsite
            : id === 'have-restaurant'
            ? css.haveRestaurant
            : id === 'about-hero'
            ? css.aboutWrapper
            : id === 'terms-of-service'
            ? css.termServices
            : id === 'privacy-policy'
            ? css.privacyPolicy
            : css.sectionContent
        }
      >
        {children}
        {id === 'hero' ? <SearchComponent onSubmit={handleSubmit} /> : null}
      </div>
    </Tag>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionContainer.defaultProps = {
  rootClassName: null,
  className: null,
  as: 'section',
  children: null,
  appearance: null,
};

SectionContainer.propTypes = {
  rootClassName: string,
  className: string,
  as: string,
  children: node,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  appearance: object,
  options: propTypeOption,
};

// export default SectionContainer;
// export default compose(
//   withRouter,
//   injectIntl
// )(SectionContainer);
export default compose(withRouter, injectIntl)(SectionContainer);
